import Swal from "sweetalert2";

const askForCancel = () =>
  Swal.fire({
    icon: "question",
    text: "Bạn có chắc chắn muốn hủy?",
    showCancelButton: true,
    cancelButtonText: "Không",
    confirmButtonText: "Hủy",
    reverseButtons: true
  });

const askForContinue = () =>
  Swal.fire({
    icon: "warning",
    text: "Vui lòng xác nhận để tiếp tục",
    showCancelButton: true,
    cancelButtonText: "Hủy",
    confirmButtonText: "Xác nhận",
    reverseButtons: true
  });

const showError = e =>
  Swal.fire({
    icon: "error",
    text: e?.errors || "Lỗi không xác định!",
    confirmButtonText: "Xác nhận"
  });

export { askForContinue, askForCancel, showError };
