<template>
  <b-modal id="giftcard-selector" size="md" title="Tìm kiếm GiftCard" centered>
    <dl class="d-flex justify-content-between align-items-center mt-3">
      <dt class="pr-4 flex-grow-0">Giftcard</dt>
      <dd class="flex-grow-1">
        <v-select
          :options="listGiftCard"
          label="code"
          v-model="selectedGiftcard"
          multiple
          @search="onSearch"
        >
          <template v-slot:no-options="{ item }">
            <em v-if="!item">Không tìm thấy Giftcard nào</em>
          </template>
        </v-select>
      </dd>
    </dl>
    <template v-slot:modal-footer>
      <div class="w-100 px-3 d-flex justify-content-between">
        <b-button
          variant="danger"
          size="sm"
          @click="(selectedGiftcard = []), $bvModal.hide('giftcard-selector')"
          >Huỷ</b-button
        >

        <b-button
          variant="primary"
          size="sm"
          @click="
            $emit('selected', selectedGiftcard),
              $bvModal.hide('giftcard-selector')
          "
          >Tiếp tục</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import axios from "@/common/api.js";

export default {
  name: "giftcard-selector-modal",
  components: { vSelect },
  data() {
    return {
      modalId: "",
      listGiftCard: [],
      selectedGiftcard: []
    };
  },
  created() {
    this.modalId = `modal-${new Date().getTime()}`;
  },
  methods: {
    onSearch: _.debounce(function($event) {
      let uri = `/secure/r2/gift-card/giftcardSold?outletId=${localStorage.getItem(
        "outletId"
      )}${$event ? "&searchText=" + $event : ""}`;

      if ($event.length > 0) {
        axios.get(uri).then(res => {
          this.listGiftCard = res.data.success;
        });
      } else {
        this.listGiftCard = [];
      }
    }, 300)
  }
};
</script>
