<template>
  <b-modal id="note-modal" size="md" title="Ghi chú mới" centered hide-footer>
    <div>
      <b-form class="modal-session">
        <b-form-group label="Tiêu đề" label-for="input-1">
          <b-form-input
            type="text"
            required
            v-model="model.title"
            aria-autocomplete="off"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Nội dung ghi chú"
          label-for="input-5"
        >
          <b-form-textarea
            v-model="model.content"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <div class="btn-footer text-right">
          <b-button size="md" class="btn-md" @click="$bvModal.hide('note-modal')"
            >Cancel</b-button
          >
          <b-button
            size="md"
            variant="primary"
            class="btn-md ml-4"
            :disabled="!model.title || !model.content"
            @click="onSave()"
            >Lưu</b-button
          >
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "note-modal",
  data() {
    return {
      model: {
        title: "",
        content: ""
      }
    };
  },
  methods: {
    onSave() {
      this.$emit("onSave", this.model);
    }
  }
};
</script>

<style></style>
