<template>
  <span>
    <span class="hover-underline ml-1" @click="openModal">{{ content }}</span>

    <b-modal :id="modalId" size="lg" :title="title" centered hide-footer>
      <div style="min-height: 430px;">
        <b-table
          striped
          :items="items"
          :fields="fields"
          responsive
          :current-page="currentPage"
          :per-page="10"
        >
          <template v-slot:cell(_id)="data">
            <span>{{ data.index + (currentPage - 1) * 10 + 1 }}</span>
          </template>
        </b-table>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="10"
        ></b-pagination>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { getGiftCardOutletTransactions } from "@/api/gift-card";

export default {
  name: "outlet-gift-card-received-modal",
  props: ["date", "data", "outletId"],
  data() {
    return {
      currentPage: 1,
      modalId: "",
      items: []
    };
  },
  methods: {
    openModal() {
      getGiftCardOutletTransactions({
        date: this.date,
        outletId: this.outletId
      }).then(res => {
        this.items = res.data.map(i => ({
          code: i.giftCode,
          value: i.value,
          name: i.sessions.map(s => s.name).join(", ")
        }));
        this.$bvModal.show(this.modalId);
      });
    }
  },
  created() {
    this.modalId = `modal-${new Date().getTime()}`;
  },
  computed: {
    fields() {
      return [
        { key: "_id", label: "STT", stickyColumn: true, variant: "light" },
        {
          key: "code",
          label: "Mã Gift Card"
        },
        {
          key: "name",
          label: "Lượt"
        },
        {
          key: "value",
          label: "Giá trị",
          class: "text-right"
        }
      ];
    },
    title() {
      return `Gift Card nhận ngày ${this.date} : (${this.total})`;
    },
    total() {
      const outletSesssions = this.data.reduce(
        (arr, b) => (arr = [...arr, ...b.data]),
        []
      );
      return outletSesssions
        .reduce((arr, s) => {
          return s.paymentMethods?.giftCard ? (arr = [...arr, s]) : arr;
        }, [])
        .reduce((a, b) => (a += b.paymentMethods.giftCard), 0);
    },
    content() {
      return `Gift Card nhận : ${this.total} |`;
    }
  }
};
</script>
