<template>
  <span>
    <span class="hover-underline ml-1" @click="openModal">{{ content }}</span>

    <b-modal :id="modalId" size="lg" :title="title" centered hide-footer>
      <div style="min-height: 430px;">
        <b-table
          striped
          :items="items"
          :fields="fields"
          responsive
          :current-page="currentPage"
          :per-page="10"
        >
          <template v-slot:cell(_id)="data">
            <span>{{ data.index + (currentPage - 1) * 10 + 1 }}</span>
          </template>

          <template v-slot:cell(typePromo)="data">
            <span v-if="data.value === 0">Phần trăm</span>
            <span v-else>Tiền</span>
          </template>

          <template v-slot:cell(currencyValue)="data">
            <p class="mb-0 text-center">
              {{ data.value }}
            </p>
          </template>
        </b-table>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="10"
        ></b-pagination>
      </div>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "outlet-promo-modal",
  props: ["date", "data"],
  data() {
    return {
      currentPage: 1,
      modalId: ""
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modalId);
    }
  },
  created() {
    this.modalId = `modal-${new Date().getTime()}`;
  },
  computed: {
    fields() {
      return [
        { key: "_id", label: "STT", stickyColumn: true, variant: "light" },
        {
          key: "name",
          label: "Tên khuyến mại"
        },
        {
          key: "sessionName",
          label: "Lượt làm việc"
        },
        {
          key: "typePromo",
          label: "Hình thức khuyến mại"
        },
        {
          key: "currencyValue",
          label: "Giá trị",
          class: "text-center"
        }
      ];
    },
    title() {
      return `Giảm giá ngày ${this.date} : (${this.total})`;
    },
    total() {
      return this.items.reduce((a, b) => (a += b.currencyValue), 0);
    },
    items() {
      const outletSesssions = this.data.reduce(
        (arr, b) => (arr = [...arr, ...b.data]),
        []
      );
      return outletSesssions.reduce((arr, s) => {
        return s.promos?.length
          ? (arr = [
              ...arr,
              ...s.promos.map(i => ({ ...i, ...{ sessionName: s.name } }))
            ])
          : arr;
      }, []);
    },
    content() {
      return `Giảm giá : ${this.total} |`;
    }
  }
};
</script>
