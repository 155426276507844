<template>
  <b-modal
    :id="modalId"
    :title="title"
    size="md"
    centered
    hide-footer
    scrollable
    no-close-on-backdrop
    no-close-on-esc
  >
    <div v-if="invoiceData" class="payment-modal">
      <div v-for="(session, index) of invoiceData.sessions" :key="index">
        <div class="d-flex justify-content-between">
          <h5 class="mb-0">
            Lượt làm việc: {{ session.name }}
            <span class="no-outline" v-b-toggle="session.name">( + )</span>
          </h5>
          <span>
            {{ session.subTotal }} - {{ session.discountTotal }} =
            {{ session.subTotal - session.discountTotal }}
          </span>
        </div>
        <b-collapse :id="session.name">
          <hr />
          <h6 class="text-underline">Dịch vụ</h6>
          <dl
            class="d-flex justify-content-between mb-0"
            v-for="item of session.orderProduct"
            :key="item._id"
          >
            <dt>{{ item.name }}</dt>
            <dd>{{ item.price }}</dd>
          </dl>

          <dl class="d-flex justify-content-between text-underline">
            <dt>Tổng doanh thu</dt>
            <dd>{{ session.subTotal }}</dd>
          </dl>

          <template v-if="session.promos && session.promos.length">
            <h6 class="text-underline">Giảm giá</h6>
            <dl
              class="d-flex justify-content-between mb-0"
              v-for="(item, index) of session.promos"
              :key="index"
            >
              <dt>{{ item.name }}</dt>
              <dd>{{ item.currencyValue }}</dd>
            </dl>

            <dl class="d-flex justify-content-between text-underline mb-0">
              <dt>Tổng giảm giá</dt>
              <dd>{{ session.discountTotal }}</dd>
            </dl>
            <hr />
          </template>
        </b-collapse>

        <dl class="d-flex justify-content-between align-items-center mt-3">
          <dt class="flex-grow-1">Tiền típ</dt>
          <dd class="flex-grow-0">
            <b-form-input
              type="number"
              min="0"
              @input="calcTotal"
              v-model="session.tipValue"
            ></b-form-input>
          </dd>
        </dl>
      </div>
      <hr />
      <dl class="d-flex justify-content-between">
        <dt>Doanh thu</dt>
        <dd>{{ invoiceData.subTotal }}</dd>
      </dl>
      <dl class="d-flex justify-content-between">
        <dt>Giảm giá</dt>
        <dd>{{ promoTotal }}</dd>
      </dl>
      <dl class="d-flex justify-content-between">
        <dt>Phải thu</dt>
        <dd>{{ invoiceData.total }}</dd>
      </dl>
      <dl class="d-flex justify-content-between">
        <dt>Tổng tip</dt>
        <dd>{{ tipTotal }}</dd>
      </dl>
      <dl class="d-flex justify-content-between">
        <dt>Trả tiền mặt</dt>
        <dd>{{ cashValue }}</dd>
      </dl>

      <dl class="d-flex justify-content-between">
        <dt class="pr-4 flex-grow-0">Trả Giftcard</dt>
        <dd>
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-input v-model="giftTotal" disabled></b-input>
            <b-input-group-append>
              <span
                class="input-group-text"
                @click="$bvModal.show('giftcard-selector')"
              >
                <i class="flaticon2-pen"></i>
              </span>
            </b-input-group-append>
          </b-input-group>
        </dd>
      </dl>
      <dl class="d-flex justify-content-between align-items-center mb-0">
        <dt class="pr-4">Trả thẻ</dt>
        <dd>
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-input
              type="number"
              v-model="cardValue"
              ref="cardInput"
            ></b-input>
            <b-input-group-append>
              <span class="input-group-text" @click="cardValue = 0">
                <i class="flaticon2-cross"></i>
              </span>
            </b-input-group-append>
          </b-input-group>
        </dd>
      </dl>

      <div class="d-flex justify-content-start pt-3">
        <b-button variant="primary" class="mt-3" @click="pay"
          >Thanh toán</b-button
        >
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <section class="loading">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </section>
    </div>
    <giftcard-selector-modal @selected="onSelectedGC"></giftcard-selector-modal>
  </b-modal>
</template>

<script>
import Loader from "@/common/loader";
import Swal from "sweetalert2";
import { getInvoice, payInvoice } from "@/api/invoice";
import GiftcardSelector from "@/components/modals/GiftcardSelector.vue";

export default {
  name: "payment-modal",
  components: { "giftcard-selector-modal": GiftcardSelector },
  props: ["invoiceId"],

  data() {
    return {
      invoiceData: null,
      cashValue: 0,
      cardValue: 0,
      selectedGcs: [],
      modalId: ""
    };
  },

  watch: {
    invoiceId(val) {
      if (!val) return;

      getInvoice(val).then(res => {
        this.invoiceData = res.data;
        this.openModal();
      });
    },
    invoiceData: {
      handler(val) {
        this.cardValue = val.total - this.giftTotal;
      },
      deep: true
    },
    cardValue(val) {
      let value = this.invoiceData.total - this.giftTotal - val;
      const total = this.invoiceData.total - this.giftTotal;
      if (val > total) this.$set(this, "cardValue", total, false);
      this.$set(this, "cashValue", value || 0, false);
    },
    giftTotal(val) {
      this.cardValue = this.invoiceData.total - this.giftTotal;
    }
  },

  methods: {
    openModal() {
      this.$bvModal.show(this.modalId);
    },
    calcTotal() {
      this.cashValue = 0;
      this.invoiceData.total =
        this.invoiceData.subTotal - this.promoTotal + this.tipTotal;
    },
    onSelectedGC(evt) {
      console.log(evt);
      this.selectedGcs = evt;
    },
    pay() {
      Loader.fire();
      const paymentData = {
        outletId: this.outletId,
        cash: this.cashValue,
        card: this.cardValue,
        promo: this.invoicePromo,
        giftCardIds: this.selectedGcs.map(item => item.code),
        tips: this.invoiceData.sessions.map(session => {
          return {
            sessionId: session._id,
            tipValue: session.tipValue || 0
          };
        })
      };

      payInvoice(this.invoiceId, paymentData)
        .then(() => {
          Loader.close();
          Swal.fire({
            icon: "success",
            titleText: "Thanh toán thành công!",
            timer: 3000,
            confirmButtonText: "Đóng"
          }).finally(() => {
            this.$bvModal.hide(this.modalId);
          });
        })
        .catch(e => {
          console.error(e);
          Swal.fire({
            icon: "error",
            titleText: "Thanh toán không thành công!",
            text: "Vui lòng kiểm tra thông tin và thử lại"
          });
        });
    }
  },

  created() {
    this.selectedGcs = [],
    this.modalId = `modal-${new Date().getTime()}`;
  },

  computed: {
    title() {
      this.selectedGcs = []
      return `Khách hàng: ${this.invoiceData?.customerName || "xxxx-xxxx"}`;
    },
    promoTotal() {
      return this.invoiceData.sessions.reduce(
        (t, s) => (t += parseInt(s.discountTotal)),
        0
      );
    },
    tipTotal() {
      return this.invoiceData.sessions.reduce((total, session) => {
        total += parseInt(session.tipValue) || 0;
        return total;
      }, 0);
    },
    giftTotal() {
      return this.selectedGcs.reduce((total, item) => {
        if (total === this.invoiceData.total) return total;
        total +=
          item.balance > this.invoiceData.total
            ? this.invoiceData.total
            : item.balance;
        return total;
      }, 0);
    }
  }
};
</script>

<style lang="scss">
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.invoice-detail-modal p {
  margin-bottom: 0;
  color: black;
}
dl,
dt,
dd {
  margin-bottom: 5px;
}
</style>
