<template>
  <span>
    <span class="hover-underline ml-1" @click="openModal">{{ content }}</span>

    <b-modal :id="modalId" size="md" :title="title" centered hide-footer>
      <div style="min-height: 430px;">
        <b-table
          striped
          :items="items"
          :fields="fields"
          responsive
          :current-page="currentPage"
          :per-page="10"
        >
          <template v-slot:cell(_id)="data">
            <span>{{ data.index + (currentPage - 1) * 10 + 1 }}</span>
          </template>
          <template v-slot:cell(paymentMethods)="data">
            <span>{{ paymentMethodsCardItem(data.item) }}</span>
          </template>
        </b-table>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="10"
        ></b-pagination>
      </div>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "outlet-card-paid-modal",
  props: ["date", "data", "totalGiftCardSoldCardPaid"],
  data() {
    return {
      currentPage: 1,
      modalId: ""
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modalId);
    },
    paymentMethodsCardItem(row) {
      return row.paymentMethods.giftCard
        ? row.paymentMethods.card
        : row.paymentMethods.card + row.tip;
    },
  },
  created() {
    this.modalId = `modal-${new Date().getTime()}`;
  },
  computed: {
    fields() {
      return [
        { key: "_id", label: "STT", stickyColumn: true, variant: "light" },
        {
          key: "name",
          label: "Lượt"
        },
        {
          key: "paymentMethods",
          label: "Số tiền",
          class: "text-right"
        }
      ];
    },
    title() {
      return `Trả thẻ ngày ${this.date} : (${this.total})`;
    },
    total() {
      let sum = 0;
      sum = this.items.reduce((a, b) => {
        if (b.paymentMethods.giftCard === 0) {
          a += b.paymentMethods.card + b.tip
        } else {
          a += b.paymentMethods.card
        }
        return a
      }, 0);
      return sum;
    },
    items() {
      const outletSesssions = this.data.reduce(
        (arr, b) => (arr = [...arr, ...b.data]),
        []
      );
      let dataItem = outletSesssions.reduce((arr, s) => {
        return s.paymentMethods?.card ? (arr = [...arr, s]) : arr;
      }, []);

      this.totalGiftCardSoldCardPaid.forEach(element => {
        if (element.paymentMethod == 0) {
          return dataItem.push({
            _id: element._id,
            name: element.seller.name + "_" + element.code,
            paymentMethods: {
              card: element.price
            }
          });
        }
      });

      return dataItem;
    },
    content() {
      return `Trả thẻ : ${this.total}`;
    }
  }
};
</script>
