import moment from "moment";
import { UserRole } from "./../../constans";

function getDashboardPermissions(user) {
  const { role = 1 } = user;

  const _permission = [
    ["canModifyColumn", role > UserRole.Cashier],

    ["canLockSession", role > UserRole.Cashier],

    ["canModifyUserOnTable", role >= UserRole.Cashier],

    ["canPrint", role > UserRole.OutletManager],

    ["canCancelPaidSesssion", role > UserRole.OutletManager],

    ["canChangeDate", role > UserRole.Cashier],

    ["showRevenue", role > UserRole.Cashier],

    ["showTotalCustomer", role > UserRole.Cashier],

    [
      "outletManagerLimitDate",
      moment()
        .subtract(14, "day")
        .toDate()
    ]
  ];

  return new Map(_permission);
}

export default getDashboardPermissions;
