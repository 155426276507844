import moment from "moment";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import Loader from "@/common/loader";
import NoteModal from "@/components/modals/Note.vue";
import AddSessionModal from "@/components/modals/session/Add.vue";
import OutletPromoModal from "@/components/modals/OutletPromo.vue";
import OutletCardPaidModal from "@/components/modals/OutletCardPaid.vue";
import OutletGiftCardReceivedModal from "@/components/modals/GiftCardReceived.vue";
import OutletGiftCardSoldModal from "@/components/modals/GiftCardSold.vue";
import PaymentModal from "@/components/modals/Payment.vue";
import { askForContinue, showError } from "@/common/swal-helper";
import Permissions from "./permission";

import {
  getDailyReportData,
  getNotes,
  getOutletUsers,
  updateDailyReportTable,
  getOutletPromos,
  getDailyReportUser
} from "@/api/outlet";
import { getActivedProduct } from "@/api/product";
import { updateSession, cancelSession } from "@/api/session";
import { createNewNote } from "@/api/user";
import { createInvoice } from "@/api/invoice";
import { getGiftCardSoldByOutlet } from "@/api/gift-card";
import { UserRole, SessionStatus, LABEL_VN, VARIANTS } from "./../../constans";

export default {
  name: "DailyReport",
  components: {
    KTPortlet,
    OutletSelect,
    vSelect,
    draggable,
    NoteModal,
    AddSessionModal,
    OutletPromoModal,
    OutletCardPaidModal,
    OutletGiftCardReceivedModal,
    OutletGiftCardSoldModal,
    PaymentModal
  },
  data() {
    return {
      limitPageload:20,
      currentPageload:1,
      dataWorker: null,
      outletSessions: [],
      customers: [],
      tableRows: [],
      users: [],
      userData: [],
      userDataTmp: [],
      availableUsers: [],
      products: [],
      promos: [],
      notes: [],
      sessionProduct: [],
      sessionPromo: [],
      selectedProduct: {},
      selectedPromo: {},
      currentSession: {},
      addNewSessionModel: {},
      outletId: "",
      currentDate: "",
      totalColNumber: 15,
      isLoading: false,
      hasModalShowing: false,
      dataDetail: [],
      userCurrent: {},
      totalGiftCardValue: "",
      totalGiftCardSoldByCurrentUser: { card: 0, cash: 0 },
      totalGiftCardSold: [],
      canCopy: false,
      modalRef: "",
      labelVN: LABEL_VN,
      variants: VARIANTS,
      permissions: null,
      userListFlag: true,
      invoiceId: null
    };
  },

  created() {
    this.dataDetail = [];
    this.permissions = Permissions(this.currentUser);
    this.pollData();
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    currentDate(oVal, nVal) {
      if (oVal) this.updateTableView();
    },
    sessionProduct(val) {
      const modalId = "update-session-modal___BV_modal_content_";
      const modal = document.getElementById(modalId);

      modal && (modal.style.height = val.length ? "100%" : "223px");
    }
  },

  methods: {
    beforeDeselectUser(user) {
      const session = this.outletSessions.find(s => s.userId === user._id);

      const totalGiftCardSold = this.totalGiftCardSold.reduce((total, b) => {
        if (b.seller._id === user._id) {
          total += b.price;
        }
        return total;
      }, 0);

      if (!session && totalGiftCardSold === 0) {
        this.userDataTmp.splice(this.userDataTmp.indexOf(user), 1);
        this.availableUsers.push(user);
      } else {
        Swal.fire({
          title: "Người dùng có lượt làm việc trong ngày, không thể xoá!",
          toast: true,
          showConfirmButton: false,
          position: "top-right",
          icon: "warning",
          timer: 2000
        });
      }
    },

    addUser2UserList(user) {
      this.userDataTmp = [...new Set([...this.userDataTmp, user])];
      this.availableUsers.splice(this.availableUsers.indexOf(user), 1);
    },

    onCancelModal(modal) {
      Swal.fire({
        text: "Bạn có chắc chắn muốn hủy thay đổi?",
        showCancelButton: true,
        cancelButtonText: "Không",
        confirmButtonText: "Hủy",
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed || res.value) {
          this.$bvModal.hide(modal);
        }
      });
    },

    onChangeUserManagementModalDate(evt) {
      Loader.fire();
      getDailyReportUser({ outletId: this.outletId, date: evt })
        .then(res => {
          // this.availableUsers = res.data;
          this.userDataTmp = res.data;
          // this.canCopy = true;
        })
        .catch(showError)
        .finally(Loader.close);
    },

    updateUserPosition() {
      askForContinue().then(res => {
        if (res.isConfirmed || res.value) {
          Loader.fire();
          this.userData = [null, ...this.cloneObject(this.userDataTmp)];
          this.updateDailyTable()
            .then(() => {
              Swal.fire({ toast: true, text: "Cập nhật thành công!" });
            })
            .catch(showError)
            .finally(() => {
              this.$bvModal.hide("user-management-modal");
              Loader.close();
            });
        }
      });
    },

    openUserMangementPopup() {
      this.availableUsers = this.users.filter(u => {
        const idx = this.userData.findIndex(i => i?._id === u._id);
        return idx === -1;
      });
      this.userDataTmp = this.cloneObject(this.userData);
      this.userDataTmp.splice(0, 1);
      this.$bvModal.show("user-management-modal");
    },

    print() {
      Swal.fire({
        icon: "question",
        title: "Xác nhận tải file PDF?",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Đóng",
        confirmButtonText: "Tải",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          Loader.fire();
          const elm = document.getElementById("print-section");
          let format = "a4";
          this.totalColNumber > 15 && (format = "a3");
          this.totalColNumber > 23 && (format = "a2");

          const opt = {
            filename: `Bảng danh sách ngày ${this.currentDate}.pdf`,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true, y: 0, imageTimeout: 10000 },
            jsPDF: { orientation: "landscape", format }
          };
          html2pdf()
            .set(opt)
            .from(elm)
            .save()
            .finally(Loader.close);
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },

    pollData() {
      this.dataWorker = setInterval(
        () =>
          !this.hasModalShowing &&
          this.outletId &&
          this.currentDate &&
          this.updateTableView(),
        5000
      );
    },

    updateDailyTable() {
      const data = {
        cols: this.totalColNumber,
        users: this.userData
          .slice(1, this.userData.length)
          .map(({ _id, name }) => ({ _id, name }))
      };
      return updateDailyReportTable(
        data,
        this.outletId,
        this.currentDate
      ).finally(this.updateTableView);
    },

    cloneObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    onSelectOutlet(outletId) {
      Loader.fire();
     const limit = this.limitPageload
     const currentPage = this.currentPageload 
      Promise.all([
        getActivedProduct(outletId),
        getOutletUsers({ outletId }),
        getOutletPromos({ outletId }),
        getNotes({ outletId,limit,currentPage })
      ]).then(async res => {
        const [products, users, promos, notes] = res;

        if (!this.currentDate) this.currentDate = moment().format("YYYY-MM-DD");

        this.outletId = outletId;
        this.products = products.data;
        this.users = users.data;
        this.promos = promos.data;
        this.notes = notes.data.notes;
        await this.updateTableView();
        Loader.close();
      });
    },

    updateTableView() {
      const payload = { outletId: this.outletId, date: this.currentDate };

      return Promise.all([
        getGiftCardSoldByOutlet(payload),
        getDailyReportData(payload)
      ]).then(res => {
        const [rGiftCard, rTableData] = res;
        this.totalGiftCardSold = rGiftCard.data;
        this.setTableData(rTableData.data);
        this.setForwardCustomerData(rTableData.data);
        this.outletSessions = rTableData.data.sessions;
        this.totalGiftCardValue = rTableData.data.totalGiftCardValue;
      });
    },

    setForwardCustomerData(data) {
      this.customers = [];
      data.sessions.forEach(user => {
        this.customers = [
          ...this.customers,
          ...user.data
            .filter(session => session.status === 1)
            .map(session => {
              return {
                code: session.customer.code,
                sessionName: session.name,
                name: session.customer.name
              };
            })
            .reduce((total, session) => {
              return [...total, session];
            }, [])
        ];
      });
    },

    setTableData(data) {
      let index = 0;
      this.totalColNumber = data.cols;
      this.userData = [null, ...data.users];
      this.tableRows = new Array(this.userData.length);

      while (index < this.userData.length) {
        this.tableRows[index] = new Array(this.totalColNumber);
        index++;
      }

      data.sessions.forEach(user => {
        user.data.forEach(session => {
          const rowNum = this.userData.findIndex(
            u => u?._id === session.userId
          );
          this.tableRows[rowNum][session.offset] = session;
        });
      });
    },

    onCreateNewSession(sessionOffset, userIndex) {
      this.addNewSessionModel = {
        customers: this.customers,
        user: this.userData[userIndex],
        offset: sessionOffset,
        date: this.currentDate,
        outletId: this.outletId
      };
      this.$bvModal.show("new-session-modal");
    },

    onSessionCreated() {
      this.$bvModal.hide("new-session-modal");
      this.updateTableView().then(Loader.close);
    },

    onUpdateSession(item) {
      // session was locked
      if (item.status === 3) {
        this.addNewSessionModel = item;
        return this.$bvModal.show("new-session-modal");
      }

      this.currentSession = this.cloneObject(item);
      this.sessionPromo = this.currentSession.promos;
      this.sessionProduct = this.currentSession.products.map(product => ({
        ...product,
        ...{ isOrderProduct: true }
      }));
      this.$bvModal.show("update-session-modal");
    },

    calcPromoValue(promos) {
      if (!promos) return;
      promos.forEach(promo => {
        promo.currencyValue =
          promo.typePromo === 0
            ? Math.ceil(
                (this.sessionProductSubTotal / 100) * parseInt(promo.value)
              )
            : parseInt(promo.value);
      });
    },

    updateSessionProduct() {
      Loader.fire();
      const products = this.sessionProduct.map(
        ({ _id, price, isOrderProduct = false }) => ({
          _id,
          price: parseInt(price),
          isOrderProduct
        })
      );
      const promos = this.sessionPromo.map(
        ({ _id, value, currencyValue, name, typePromo }) => ({
          name,
          promoId: _id,
          typePromo,
          value: parseInt(value),
          currencyValue: parseInt(currencyValue)
        })
      );

      return updateSession(this.currentSession._id, { products, promos })
        .then(this.updateTableView)
        .then(() => this.$bvModal.hide("update-session-modal"))
        .finally(Loader.close);
    },

    addColum() {
      this.totalColNumber += 1;
      this.tableRows = this.tableRows.map(row => (row.length++, row));

      const sessionTable = document.getElementById("session-table");
      sessionTable.scrollLeft = sessionTable.clientWidth;
      this.updateDailyTable();
    },

    removeColum(idx) {
      Swal.fire({
        icon: "question",
        title: "Xác nhận xoá cột này?",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Đóng",
        confirmButtonText: "Xoá",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let allowToRemove = true;
          for (let elm of this.tableRows) {
            if (elm[idx]) {
              allowToRemove = false;
              break;
            }
          }
          if (allowToRemove) {
            Loader.fire();
            this.totalColNumber -= 1;
            this.updateDailyTable().finally(Loader.close);
          } else {
            Swal.fire({
              icon: "error",
              toast: true,
              timer: 2000,
              position: "top-right",
              showConfirmButton: false,
              title: "Không thể xoá nếu cột có lượt làm việc!"
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },

    totalGrowSaleOfUser(userIndex) {
      if (!this.tableRows[userIndex]) return 0;
      return this.tableRows[userIndex].reduce((total, session) => {
        if (session.status === 2) {
          total += session.productTotal || 0;
        }
        return total;
      }, 0);
    },

    totalCustomerOfUser(userIndex) {
      if (!this.tableRows[userIndex]) return 0;
      return this.tableRows[userIndex].reduce((total, session) => {
        total += session && session.status === 2 ? 1 : 0;
        return total;
      }, 0);
    },

    addNewNote(data) {
      if (this.outletId) {
        Loader.fire();
        createNewNote({ ...data, ...{ outletId: this.outletId } })
          .then(() => getNotes({ outletId: this.outletId }))
          .then(res => {
            this.notes = res.data.notes;
            Loader.close();
            this.$bvModal.hide("note-modal");
          });
      } else {
        Swal.fire({
          icon: "warning",
          text: "Chưa có tiệm nào được chọn, hãy kiểm tra lại!",
          confirmButtonText: "Đồng ý",
          reverseButtons: true
        });
      }
    },

    formatDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "Không có ngày được chọn!";
      }
    },

    formatTime(time) {
      return moment(time).format("H:mm");
    },

    noteScrollHandler(evt) {
      
      if (this.isLoading) return;
      if (
        evt.target.scrollTop + evt.target.clientHeight ===
        evt.target.scrollHeight
      ) {
        this.isLoading = true;
        const outletId = this.outletId;
        const limit= this.limitPageload;
        const currentPage = this.currentPageload++
        getNotes({outletId,limit,currentPage}).then(res => {
          this.notes = [...this.notes, ...res.data.notes];
          this.isLoading = false;
         
        });
      }
    },

    productShortName(products) {
      if (!products.length) return "";
      let shortName = products.reduce((name, product) => {
        if (product.shortName) {
          name.push(product.shortName);
        }
        return name;
      }, []);
      return shortName.length ? `(${shortName.join(",")})` : "";
    },

    onCancelSession() {
      askForContinue().then(res => {
        if (res.isConfirmed || res.value) {
          Loader.fire();
          cancelSession(this.currentSession._id)
            .then(this.updateTableView)
            .then(() => this.$bvModal.hide("update-session-modal"))
            .finally(Loader.close);
        }
      });
    },

    addToCart() {
      Loader.fire();
      this.updateSessionProduct()
        .then(() =>
          createInvoice({
            date: this.currentSession.date,
            customer: this.currentSession.customer,
            outletId: this.outletId
          })
        )
        .then(res => {
          this.$bvModal.hide("update-session-modal");
          this.invoiceId = res.data;
        })
        // .catch(e => {
        //   console.debug(e.errors)
        //   Loader.close();
        //   e.errors === "Khách hàng có lượt làm việc chưa hòan thành, không thể thanh tóan!" ? showError() : showError();
        // })
        .catch(showError)
        .finally(Loader.close);
    },

    getItemContent(item) {
      switch (item.status) {
        case SessionStatus.InProcess:
        case SessionStatus.ReadyToPay:
        case SessionStatus.Paid: {
          return `${item.productTotal || ""} ${this.productShortName(
            item.products
          )}`;
        }
        case SessionStatus.Locked: {
          return '<i class="flaticon2-lock pr-0"></i>';
        }
      }
    },

    canCreateNewSession(user) {
      const { role, id } = this.currentUser;

      if (role === UserRole.Staff && user._id != id) {
        return false;
      } else {
        return true;
      }
    },

    getEmptyItemContent(rowNum) {
      return this.canCreateNewSession(this.userData[rowNum])
        ? "+"
        : '<i class="flaticon2-lock pr-0"></i>';
    },

    detailReportUser(user, index) {
      this.userCurrent = user;
      this.totalGiftCardSoldByCurrentUser.card = 0;
      this.totalGiftCardSoldByCurrentUser.cash = 0;
      this.totalGiftCardSold.forEach(elm => {
        if (elm.seller._id === user._id) {
          switch (elm.paymentMethod) {
            case 0: {
              this.totalGiftCardSoldByCurrentUser.card += elm.price;
              break;
            }

            case 1: {
              this.totalGiftCardSoldByCurrentUser.cash += elm.price;
              break;
            }

            default: {
              // it's gift
            }
          }
        }
      });
      this.dataDetail = this.tableRows[index];

      this.$bvModal.show("user-detail-modal");
    },

    paymentMethodsCard(row) {
      let sum = 0;
      if (
        row.paymentMethods &&
        row.paymentMethods.card &&
        !row.paymentMethods.giftCard
      ) {
        sum += row.paymentMethods.card + row.tip;
      } else if (row.paymentMethods && row.paymentMethods.giftCard) {
        sum += row.paymentMethods.card;
      }
      return sum;
    },

    totalItemPromoUser(item) {
      return item.promos.reduce(
        (total, promo) => (total += promo.currencyValue),
        0
      );
    }
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.hasModalShowing = true;
      document.body.classList.add("anti-scroll");
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.hasModalShowing = false;
      this.sessionProduct = [];
      this.sessionPromo = [];
      this.selectedProduct = {};
      this.selectedPromo = {};
      this.modalRef = "";
      document.body.classList.remove("anti-scroll");
    });
  },
  computed: {
    isMobile() {
      return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    currentUser() {
      return JSON.parse(localStorage.getItem("user"));
    },

    outletGiftCardSold() {
      return this.totalGiftCardSold.reduce((total, b) => (total += b.price), 0);
    },

    totalCashUser() {
      return this.dataDetail.reduce(
        (total, s) => (total += s.paymentMethods?.cash || 0),
        0
      );
    },

    totalCardUser() {
      let sum = 0;
      sum = this.dataDetail.reduce((a, b) => {
        if (!b.paymentMethods) return a;

        if (b.paymentMethods.giftCard === 0) {
          a += b.paymentMethods.card + b.tip;
        } else {
          a += b.paymentMethods.card;
        }
        return a;
      }, 0);
      return sum;
    },

    totalGiftCardUser() {
      return this.dataDetail.reduce(
        (total, s) => (total += s.paymentMethods?.giftCard || 0),
        0
      );
    },

    totalTipUser() {
      return this.dataDetail.reduce(
        (total, s) => (total += parseInt(s?.tip || 0)),
        0
      );
    },

    totalProductUser() {
      return this.dataDetail.reduce(
        (total, s) => (total += s?.productTotal || 0),
        0
      );
    },

    totalPromoUser() {
      return this.dataDetail.reduce(
        (total, s) =>
          (total += s.promos.reduce((a, b) => {
            return (a += b.currencyValue || 0);
          }, 0)),
        0
      );
    },

    datePickerMinRange() {
      return this.currentUser.role === UserRole.OutletManager
        ? this.permissions.get("outletManagerLimitDate")
        : "";
    },

    datePickerMaxRange() {
      return this.currentUser.role === UserRole.OutletManager
        ? moment()
            .add(14, "day")
            .toDate()
        : "";
    },

    sessionProductFields() {
      return [
        { key: "name", label: "Dịch vụ" },
        {
          key: "price",
          label: "Doanh thu",
          class: "modal-table-cell text-center"
        },
        {
          key: "remove",
          label: "",
          class: "modal-table-cell text-center remove"
        }
      ];
    },

    sessionProductSubTotal() {
      if (!this.sessionProduct.length) return 0;
      return this.sessionProduct.reduce(
        (total, product) => (total += parseInt(product.price)),
        0
      );
    },

    sessionPromoFields() {
      return [
        { key: "name", label: "Giảm giá" },
        {
          key: "value",
          label: "Giá trị",
          class: "modal-table-cell text-center"
        },
        {
          key: "remove",
          label: "",
          class: "modal-table-cell text-center remove"
        }
      ];
    },

    sessionPromoTotal() {
      if (!this.sessionPromo.length) return 0;
      return this.sessionPromo.reduce(
        (total, promo) => (total += parseInt(promo.currencyValue)),
        0
      );
    },

    totalCustomer() {
      if (!this.outletSessions.length) return 0;
      const customerArr = [];
      this.outletSessions.forEach(s => {
        s.data.forEach(d => {
          if (d.customer?.code && !customerArr.includes(d.customer.code))
            customerArr.push(d.customer.code);
        });
      });
      return customerArr.length;
    },

    totalCard() {
      let sum = 0;
      const outletSesssions = this.outletSessions.reduce(
        (arr, b) => (arr = [...arr, ...b.data]),
        []
      );
      let dataItem = outletSesssions.reduce((arr, s) => {
        return s.paymentMethods?.card ? (arr = [...arr, s]) : arr;
      }, []);

      this.totalGiftCardSold.forEach(element => {
        if (element.paymentMethod == 0) {
          return dataItem.push({
            _id: element._id,
            name: element.seller.name + "_" + element.code,
            paymentMethods: {
              card: element.price
            }
          });
        }
      });

      sum += dataItem.reduce((a, b) => {
        if (b.paymentMethods.giftCard === 0) {
          a += b.paymentMethods.card + b.tip;
        } else {
          a += b.paymentMethods.card;
        }
        return a;
      }, 0);
      return sum;
    },

    // totalGiftCardSoldByCurrentUser(totalGiftCardSoldByCurrentUser) {
    //   return 0
    // },

    totalGiftCardReceived() {
      if (!this.outletSessions.length) return 0;
      return this.outletSessions.reduce((total, sessionsOfUser) => {
        total += sessionsOfUser.data.reduce((paymentMethodsCardTotal, item) => {
          paymentMethodsCardTotal += parseInt(
            item.paymentMethods?.giftCard || 0
          );
          return paymentMethodsCardTotal;
        }, 0);
        return total;
      }, 0);
    },

    totalRevenue() {
      if (!this.outletSessions.length) return 0;
      return this.outletSessions.reduce((total, sessionsOfUser) => {
        total += sessionsOfUser.data.reduce((sessionPromoTotal, session) => {
          if (session.status === 2) {
            sessionPromoTotal += session.productTotal;
          }
          return sessionPromoTotal;
        }, 0);
        return total;
      }, 0);
    }
  },
  beforeDestroy() {
    clearInterval(this.dataWorker);
  }
};
