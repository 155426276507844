<template>
  <div>
    <b-modal
      id="new-session-modal"
      :size="modalSize"
      :title="modalTitle"
      centered
      hide-footer
      @hidden="resetCustomerField"
      no-close-on-backdrop
    >
      <b-form class="modal-session" v-if="sessionData.status != 3">
        <b-form-group label="Tạo khách mới / Khách chuyển tới">
          <v-select
            :options="sessionData.customers"
            :filter="customersFilterHandler"
            v-model="selectedCustomer"
            :getOptionLabel="getOptionLabel"
          >
            <template v-slot:no-options="{ item }">
              <em v-if="!item"></em>
            </template>

            <template v-slot:option="option">
              <span>{{ option.name }} - {{ option.sessionName }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
      <div
        class="d-flex"
        :class="{
          'justify-content-between': !locked && hasPermission,
          'justify-content-center': locked,
          'justify-content-end': !hasPermission
        }"
      >
        <b-button
          class="btn-status"
          variant="warning"
          @click="lockOffset()"
          v-if="hasPermission"
          >{{ locked ? "Mở lại lượt này" : "Khóa lượt" }}</b-button
        >

        <b-button
          variant="primary"
          class="ml-3"
          :disabled="!selectedCustomer && !customerName"
          @click="
            createNewSession(
              `${sessionData.user.shortName}:${sessionData.offset + 1}`
            )
          "
          v-if="!locked"
          >Xác nhận</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createSession, cancelSession } from "@/api/session";
import Loader from "@/common/loader";
import Swal from "sweetalert2";
import { SessionStatus, UserRole } from "../../../constans";
import vSelect from "vue-select";

export default {
  name: "add-session-modal",
  props: ["sessionData"],
  components: {
    vSelect
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("user")),
      selectedCustomer: "Tên khách",
      customerName: "Tên khách",
      dataResultSearch: []
    };
  },
  methods: {
    resetCustomerField() {
      this.customerName = "Tên khách";
      this.selectedCustomer = "Tên khách";
    },

    customersFilterHandler(options, search) {
      const dataTemp = options.filter(option => option.name.includes(search));
      if (dataTemp.length) return dataTemp;
      else {
        this.customerName = search.trim();
        return [];
      }
    },

    createNewSession(name) {
      Loader.fire();
      const customerCode =
        typeof this.selectedCustomer !== "string"
          ? this.selectedCustomer.code
          : undefined;
      const customerName = this.selectedCustomer?.name || this.customerName;

      const newSessionData = {
        customerName,
        userId: this.sessionData.user._id,
        offset: this.sessionData.offset,
        date: this.sessionData.date,
        outletId: this.sessionData.outletId,
        name: name
      };

      if (customerCode) newSessionData["customerCode"] = customerCode;

      createSession(newSessionData)
        .then(res => this.$emit("created", res.data))
        .catch(e => {
          Loader.close();
          Swal.fire({
            icon: "error",
            text: e.errors,
            cancelButtonText: "Đóng",
            showConfirmButton: false,
            showCancelButton: true
          });
        })
        .finally(() => {
          this.customerName = "";
          this.selectedCustomer = "";
        });
    },

    lockOffset() {
      const vm = this;

      if (this.locked) {
        Swal.fire({
          icon: "warning",
          text: "Bạn có chắc muốn mở khóa lượt làm việc",
          cancelButtonText: "Đóng",
          confirmButtonText: "Đồng ý",
          showCancelButton: true,
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed || res.value) {
            Loader.fire();

            cancelSession(vm.sessionData._id).finally(() => {
              vm.$emit("created", res.data);
              Loader.close();
            });
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          text: "Bạn có chắc muốn khóa lượt làm việc",
          cancelButtonText: "Đóng",
          confirmButtonText: "Đồng ý",
          showCancelButton: true,
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed || res.value) {
            Loader.fire();
            const newSessionData = {
              userId: vm.sessionData.user._id,
              offset: vm.sessionData.offset,
              date: vm.sessionData.date,
              outletId: vm.sessionData.outletId,
              status: SessionStatus.Locked
            };

            createSession(newSessionData).then(res => {
              vm.$emit("created", res.data);
              Loader.close();
            });
          }
        });
      }
    },

    getOptionLabel(option) {
      if (typeof option === "object") {
        return `${option.name} - ${option.sessionName}`;
      } else {
        return option;
      }
    }
  },
  computed: {
    hasPermission() {
      const { role } = this.currentUser;
      return role >= UserRole.Cashier ? true : false;
    },
    locked() {
      return this.sessionData.status === SessionStatus.Locked;
    },
    modalSize() {
      return this.locked ? "sm" : "md";
    },
    modalTitle() {
      return this.locked ? "Mở khóa phiên làm việc" : "Tạo phiên mới";
    }
  }
};
</script>

<style></style>
