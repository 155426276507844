var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"new-session-modal","size":_vm.modalSize,"title":_vm.modalTitle,"centered":"","hide-footer":"","no-close-on-backdrop":""},on:{"hidden":_vm.resetCustomerField}},[(_vm.sessionData.status != 3)?_c('b-form',{staticClass:"modal-session"},[_c('b-form-group',{attrs:{"label":"Tạo khách mới / Khách chuyển tới"}},[_c('v-select',{attrs:{"options":_vm.sessionData.customers,"filter":_vm.customersFilterHandler,"getOptionLabel":_vm.getOptionLabel},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var item = ref.item;
return [(!item)?_c('em'):_vm._e()]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name)+" - "+_vm._s(option.sessionName))])]}}],null,false,1983134616),model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex",class:{
        'justify-content-between': !_vm.locked && _vm.hasPermission,
        'justify-content-center': _vm.locked,
        'justify-content-end': !_vm.hasPermission
      }},[(_vm.hasPermission)?_c('b-button',{staticClass:"btn-status",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.lockOffset()}}},[_vm._v(_vm._s(_vm.locked ? "Mở lại lượt này" : "Khóa lượt"))]):_vm._e(),(!_vm.locked)?_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","disabled":!_vm.selectedCustomer && !_vm.customerName},on:{"click":function($event){return _vm.createNewSession(
            ((_vm.sessionData.user.shortName) + ":" + (_vm.sessionData.offset + 1))
          )}}},[_vm._v("Xác nhận")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }