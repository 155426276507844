<template>
  <span>
    <span class="hover-underline ml-1" @click="openModal">{{ content }}</span>

    <b-modal :id="modalId" size="lg" :title="title" centered hide-footer>
      <div style="min-height: 430px;">
        <b-table
          striped
          :items="items"
          :fields="fields"
          responsive
          :current-page="currentPage"
          :per-page="10"
        >
          <template v-slot:cell(_id)="data">
            <span>{{ data.index + (currentPage - 1) * 10 + 1 }}</span>
          </template>
          <template v-slot:cell(paymentMethods)="data">
            <span v-if="data.item.paymentMethod === 0">Trả thẻ</span>
            <span v-else-if="data.item.paymentMethod === 1">Tiền mặt</span>
            <span v-else>Tặng</span>
          </template>
        </b-table>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="10"
        ></b-pagination>
      </div>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "outlet-gift-card-sold-modal",
  props: ["date", "data"],
  data() {
    return {
      currentPage: 1,
      modalId: ""
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modalId);
    }
  },
  created() {
    this.modalId = `modal-${new Date().getTime()}`;
  },
  computed: {
    fields() {
      return [
        { key: "_id", label: "STT", stickyColumn: true, variant: "light" },
        {
          key: "code",
          label: "Mã Gift Card"
        },
        {
          key: "seller.name",
          label: "Người bán"
        },
        {
          key: "paymentMethods",
          label: "Hình thức thanh toán"
        },
        {
          key: "price",
          label: "Giá trị"
        }
      ];
    },
    title() {
      return `Gift Card bán ngày ${this.date} : (${this.total})`;
    },
    total() {
      return this.items.reduce((a, b) => (a += b.price), 0);
    },
    items() {
      return this.data;
    },
    content() {
      return `Gift Card bán : ${this.total} |`;
    }
  }
};
</script>
